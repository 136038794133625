import React, { FunctionComponent, useState } from "react";
import { IHeader, IPagination, IProduct, ISort } from "utils/models";
import { _paginate30 } from "utils/states";
import Pagination from "global/pagination/Pagination";
import { Table } from "rsuite";
import { useHistory } from "react-router-dom";
import HeaderButtons from "global/atoms/headerButtons/HeaderButtons";
import ActionsContainer from "../../../../global/atoms/ActionsContainer";
import { deepClone, handleSortColumn } from "../../../../utils/helpers";
import ButtonListRedirect from "../../../../global/atoms/ButtonListRedirect";
import UniversalFilters, {
  FILTER_INPUT_TEXT,
} from "../../../../global/filters/UniversalFilters";
import NavOptionsWrapper from "../../../../utils/NavOptionsWrapper";
import PaginatedTableViewWrapper from "global/PaginatedTableViewWrapper";
import VisitReportsSchedulerConnections from "./VisitReportsSchedulerConnections";
import dayjs from "dayjs";
import TrashIcon from "@rsuite/icons/Trash";
import EditIcon from "@rsuite/icons/Edit";
import { confirmModalHandler } from "../../../../redux/store";

interface IGetScheduler {
  projectId: string;
  requestPaginate: IPagination;
  requestOrder: ISort;
}

const getGetSchedulerState: IGetScheduler = {
  projectId: "",
  ..._paginate30,
};

export interface ISchedulerList {
  columns: {
    action: string;
    brand: string;
    gtin: string;
    name: string;
    price: string;
  };
  header: IHeader;
  count: number;
  data: Array<IProduct>;
}

interface IPropsSchedulerList {}

const ProductsList: FunctionComponent<IPropsSchedulerList> = () => {
  const domainUrl = "/global-reports/visit-report/scheduler";
  const [data, setData] = useState<ISchedulerList | null>(null);
  const defaultFilters = deepClone(getGetSchedulerState);
  const [filters, setFilters] = useState<IGetScheduler>(defaultFilters);
  const history = useHistory();
  const [triggerLoad, setTriggerLoad] = useState(0);
  const [loading, setLoading] = useState<boolean>(true);

  const handleEdit = (id: string) => {
    history.push(`${domainUrl}/${id}`);
  };

  const handleDelete = (id: string) => {
    VisitReportsSchedulerConnections.schedulerDelete(id).then(() => {
      setTriggerLoad(triggerLoad + 1);
    });
  };

  return (
    <>
      <HeaderButtons>
        <NavOptionsWrapper addPath={`${domainUrl}/add`} />
      </HeaderButtons>

      <PaginatedTableViewWrapper
        table={
          <Table
            loading={loading}
            fillHeight
            headerHeight={70}
            data={data?.data}
            sortColumn={filters.requestOrder.field}
            sortType={filters.requestOrder.order}
            onSortColumn={(dataKey, sortType) =>
              handleSortColumn(dataKey, sortType, setFilters)
            }>
            <Table.Column flexGrow={1} minWidth={100} align="left">
              <Table.HeaderCell>Prefiks raportu</Table.HeaderCell>
              <Table.Cell dataKey={"prefixName"}>
                {(rowData) => (
                  <ButtonListRedirect
                    redirectUrl={`${domainUrl}/${rowData.id}`}>
                    {rowData.prefixName}
                  </ButtonListRedirect>
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1} minWidth={100} align="left">
              <Table.HeaderCell>Wygenerowano</Table.HeaderCell>
              <Table.Cell dataKey={"lastGenerationDateTime"} />
            </Table.Column>
            <Table.Column flexGrow={1} minWidth={100} align="left">
              <Table.HeaderCell>Zaplanowane na</Table.HeaderCell>
              <Table.Cell dataKey={"nextGenerationDateTime"}>
                {(rowData) => {
                  return rowData.nextGenerationDateTime
                    ? rowData.nextGenerationDateTime
                    : '-';
                }}
              </Table.Cell>
            </Table.Column>
            <Table.ColumnGroup header="Terminy">
              <Table.Column width={150}>
                <Table.HeaderCell>data od</Table.HeaderCell>
                <Table.Cell dataKey={"frequencyDateStart"}>
                  {(rowData) => {
                    return dayjs(rowData.frequencySettings.dateStart).format(
                      "YYYY-MM-DD"
                    );
                  }}
                </Table.Cell>
              </Table.Column>
              <Table.Column width={150}>
                <Table.HeaderCell>data do</Table.HeaderCell>
                <Table.Cell dataKey={"frequencyDateEnd"}>
                  {(rowData) => {
                    return rowData.frequencySettings.dateEnd
                      ? dayjs(rowData.frequencySettings.dateEnd).format("YYYY-MM-DD")
                      : "-";
                  }}
                </Table.Cell>
              </Table.Column>
            </Table.ColumnGroup>
            <Table.ColumnGroup header="Powtarzanie">
              <Table.Column width={150}>
                <Table.HeaderCell>w dniach</Table.HeaderCell>
                <Table.Cell>
                  {(rowData) => {
                    return rowData.frequencySettings.days.join(", ");
                  }}
                </Table.Cell>
              </Table.Column>
              <Table.Column width={150}>
                <Table.HeaderCell>Częstotliwość (dni)</Table.HeaderCell>
                <Table.Cell dataKey={"frequencySettings.dayType"} />
              </Table.Column>
              <Table.Column width={150}>
                <Table.HeaderCell>Częstotliwość (czas)</Table.HeaderCell>
                <Table.Cell>
                  {(rowData) => {
                    return (
                      rowData.frequencySettings.timeType +
                      (rowData.frequencySettings.timeType === "RECURRING"
                        ? " (" + rowData.frequencySettings.interval + " min)"
                        : "")
                    );
                  }}
                </Table.Cell>
              </Table.Column>
            </Table.ColumnGroup>
            <Table.Column
              verticalAlign={"middle"}
              align="right"
              fixed={"right"}>
              <Table.HeaderCell>Akcje</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => (
                  <ActionsContainer>
                    <EditIcon
                      style={{ cursor: "pointer", color: "E09616" }}
                      onClick={handleEdit.bind(null, rowData.id)}
                    />
                    <TrashIcon
                      style={{ cursor: "pointer", color: "E09616" }}
                      onClick={confirmModalHandler(
                        `Czy usunąć wpis? ${rowData.prefixName}`,
                        handleDelete.bind(null, rowData.id)
                      )}
                    />
                  </ActionsContainer>
                )}
              </Table.Cell>
            </Table.Column>
          </Table>
        }
        pagination={
          <Pagination
            count={data?.count ?? 0}
            page={filters.requestPaginate.page.toString()}
            limit={filters.requestPaginate.limit.toString()}
            setState={setFilters}
          />
        }
      />

      <UniversalFilters
        load={VisitReportsSchedulerConnections.scheduleList}
        formGet={VisitReportsSchedulerConnections.getFiltersScheduler}
        setLoading={setLoading}
        state={filters}
        setState={setFilters}
        defaultStateData={defaultFilters}
        setResultData={setData}
        filterStorageKey={"visitReportSchedulerList"}
        triggerLoad={triggerLoad}
        elements={[
          {
            type: FILTER_INPUT_TEXT,
            label: "Prefiks",
            stateKey: "prefixName",
          },
        ]}
      />
    </>
  );
};

export default ProductsList;
