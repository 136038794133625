import React, { useCallback, useEffect, useState } from "react";
import TargetConnections from "./TargetConnections";
import Section from "../../../../global/atoms/section/Section";
import Spinner from "../../../../global/atoms/Spinner/Spinner";
import { AxiosResponse } from "axios";
import SeparatorLine from "../../../../global/atoms/separators/SeparatorLine";
import {
  Badge,
  Col,
  Form,
  IconButton,
  InputGroup,
  Row,
  SelectPicker,
} from "rsuite";
import CloseIcon from "@rsuite/icons/Close";
import { ITargetForm, IUserEntity } from "./TargetsEdit";
import FunnelIcon from "@rsuite/icons/Funnel";
import SeparatorEmpty from "../../../../global/atoms/separators/SeparatorEmpty";
import { FCC } from "../../../../utils/models";
import { uniqBy } from "lodash";

export interface IFilter {
  textSearch: string;
  networkId: string;
  locationId: string;
}

interface ITargetModel {
  users: Array<{ userId: string; value: string }>;
}

interface ITargetUsersProps {
  children?: React.ReactNode;
  projectId: string;
  targetId: string;
  targetModel: Partial<ITargetModel>;
  setTargetModel: (s: (s) => any) => void;
  targetForm: Partial<ITargetForm>;
  setTargetForm: (s: (s) => any) => void;
}

interface IUserFilter {
  textSearch: string;
  networkId: string;
  locationId: string;
}

const TargetUsers: FCC<ITargetUsersProps> = (props) => {
  const [targetCommonValue, setTargetCommonValue] = useState<string>("");
  const [userFilter, setUserFilter] = useState<IUserFilter>({
    textSearch: "",
    networkId: "",
    locationId: "",
  });
  const [isFilterActive, setIsFilterActive] = useState<boolean>(false);
  useEffect(() => {
    if (!props?.targetForm?.users)
      TargetConnections.getTargetUsersForm(
        props.projectId,
        props.targetId
      ).then((response: AxiosResponse<any>) => {
        props.setTargetForm((s) => ({ ...s, users: response.data.users.data }));
      });
  }, []);

  const handleTargetChange = (userId: string, value: string) => {
    const _users =
      props?.targetModel?.users?.filter((u) => u.userId !== userId) ?? [];
    _users.push({ userId: userId, value: value });

    props.setTargetModel((s) => ({
      ...s,
      users: _users,
    }));
  };
  const getUsers = () => {
    if (!props?.targetForm?.users) return [];
    const parsed: Array<IUserEntity> = [];
    let tmp = props?.targetForm?.users;
    const filter = userFilter;

    /* filtering by network */
    if (filter.networkId)
      tmp = tmp.filter((u) => filter.networkId === u.networkId);
    if (filter.locationId)
      tmp = tmp.filter((u) => filter.locationId === u.locationId);

    /* filtering by text */
    if (filter.textSearch)
      tmp = tmp.filter(
        (u) =>
          u.userName.toLowerCase().indexOf(filter.textSearch.toLowerCase()) >=
            0 ||
          u.locationName
            .toLowerCase()
            .indexOf(filter.textSearch.toLowerCase()) >= 0 ||
          u.networkName
            .toLowerCase()
            .indexOf(filter.textSearch.toLowerCase()) >= 0
      );

    // get id of duplicates
    tmp.forEach((u) => {
      if (parsed.find((pu) => pu.userId === u.userId) === undefined) {
        parsed.push(u);
      }
    });
    // @ts-ignore
    return uniqBy(parsed ?? [], "userId");
  };

  const networks = useCallback(() => {
    const networks =
      props?.targetForm?.users?.reduce(
        (networks, network) => ({
          ...networks,
          [network.networkId]: network.networkName,
        }),
        {}
      ) ?? {};

    return (
      Object.entries(networks).map(([key, value]) => ({
        networkId: key,
        networkName: value,
      })) ?? []
    ).sort((a, b) =>
      (a.networkName as string).localeCompare(b.networkName as string)
    );
  }, [props?.targetForm?.users]);

  const locations = useCallback(() => {
    const locations =
      props?.targetForm?.users
        ?.filter((u) =>
          userFilter.networkId ? u.networkId == userFilter.networkId : true
        )
        ?.reduce(
          (locations, location) => ({
            ...locations,
            [location.locationId]: location.locationName,
          }),
          {}
        ) ?? {};
    return (
      Object.entries(locations).map(([key, value]) => ({
        locationId: key,
        locationName: value,
      })) ?? []
    ).sort((a, b) =>
      (a.locationName as string).localeCompare(b.locationName as string)
    );
  }, [props?.targetForm?.users, userFilter.networkId]);

  if (!props?.targetForm?.users) return <Spinner />;
  const formSize = "sm";
  const userFilterComponent = () => {
    return (
      <Form
        fluid
        onChange={(model: Partial<IFilter>) => {
          setUserFilter((s) => ({ ...s, ...model }));
        }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
          }}>
          <div style={{ display: "flex", gap: "10px", width: "100%" }}>
            <Col xs={12}>
              <Form.Control
                size={formSize}
                name={"textSearch"}
                value={userFilter.textSearch}
                placeholder={"Wyszukaj"}
              />
            </Col>
            <Col xs={4}>
              <IconButton
                icon={<FunnelIcon />}
                size={formSize}
                appearance={isFilterActive ? "primary" : "default"}
                onClick={() => {
                  isFilterActive &&
                    setUserFilter({
                      textSearch: "",
                      networkId: "",
                      locationId: "",
                    });
                  setIsFilterActive(!isFilterActive);
                }}>
                Filtry
              </IconButton>
            </Col>
          </div>
          {isFilterActive && (
            <>
              <Col xs={24}>
                <Form.ControlLabel>Sieć</Form.ControlLabel>
                <Form.Control
                  block
                  size={formSize}
                  name={"networkId"}
                  labelKey={"networkName"}
                  valueKey={"networkId"}
                  accepter={SelectPicker}
                  value={userFilter.networkId}
                  data={networks()}
                  placeholder={"Sieć"}
                />
              </Col>
              <Col xs={24}>
                <Form.ControlLabel>
                  Lokalizacja&nbsp;
                  <Badge color={"orange"} content={locations().length} />
                </Form.ControlLabel>
                <Form.Control
                  block
                  size={formSize}
                  name={"locationId"}
                  labelKey={"locationName"}
                  valueKey={"locationId"}
                  accepter={SelectPicker}
                  value={userFilter.locationId}
                  data={locations()}
                  placeholder={"Lokalizacja"}
                />
              </Col>
            </>
          )}
        </div>
        <SeparatorEmpty />
      </Form>
    );
  };
  return (
    <>
      <Section title={"Pracownicy"}>
        {userFilterComponent()}
        <SeparatorLine />
        <Form fluid>
          <Row
            style={{
              paddingBottom: "10px",
              paddingTop: "10px",
              marginBottom: "10px",
              borderBottom: "1px solid rgba(93, 92, 92, 0.16)",
              backgroundColor: "#FBFBFB",
              fontWeight: "bold",
              color: "#13131552",
            }}>
            <Col
              xs={12}
              style={{
                fontWeight: "normal",
                textAlign: "right",
                paddingTop: "15px",
              }}>
              Ustaw wartość dla wszystkich
            </Col>
            <Col xs={12}>
              <Form.Group controlId={"name"}>
                <InputGroup style={{ width: "100%" }}>
                  <Form.Control
                    name={"all_update"}
                    value={targetCommonValue}
                    onChange={(value: string) => {
                      if (!value) return;
                      const cleaned_value = value.replace(/\D/g, "");
                      setTargetCommonValue(cleaned_value);
                      const _users = getUsers().map((user) => ({
                        userId: user.userId,
                        value: cleaned_value,
                      }));
                      props.setTargetModel((s) => ({
                        ...s,
                        users: _users,
                      }));
                    }}
                  />
                  {targetCommonValue && (
                    <InputGroup.Addon
                      onClick={() => {
                        setTargetCommonValue("");
                      }}>
                      <CloseIcon style={{ cursor: "pointer" }} />
                    </InputGroup.Addon>
                  )}
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
          {getUsers().map((user, key) => {
            const inputValue = props?.targetModel?.users?.find(
              (u) => u?.userId === user.userId
            )?.value;
            return (
              <>
                <Row
                  style={{
                    paddingBottom: "5px",
                    backgroundColor: key % 2 == 0 ? "#FBFBFB" : "white",
                  }}>
                  <Col
                    xs={12}
                    style={{ paddingTop: "15px", paddingLeft: "5px" }}>
                    {user.userName}
                  </Col>
                  <Col xs={12}>
                    <Form.Group>
                      <InputGroup style={{ width: "100%" }}>
                        <Form.Control
                          name={`target-${user.userId}-${key}`}
                          value={inputValue}
                          onChange={(value: string) => {
                            const cleaned_value = value.replace(/\D/g, "");
                            handleTargetChange(user.userId, cleaned_value);
                          }}
                        />
                        {inputValue && (
                          <InputGroup.Addon
                            onClick={() => {
                              handleTargetChange(user.userId, "");
                            }}>
                            <CloseIcon style={{ cursor: "pointer" }} />
                          </InputGroup.Addon>
                        )}
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>
              </>
            );
          })}
        </Form>
      </Section>
    </>
  );
};

export default TargetUsers;
