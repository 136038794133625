import React, { FunctionComponent, useState } from "react";
import Modal from "rsuite/Modal";
import {
  Checkbox,
  Col,
  DatePicker,
  IconButton,
  InputNumber,
  Message,
  Nav,
  SelectPicker,
  TagPicker,
  Toggle,
} from "rsuite";
import { IModalBatchAddData, ScheduleInterval } from "../Schedule";
import { IUserSimple } from "../../../utils/models";
import SeparatorEmpty from "../../../global/atoms/separators/SeparatorEmpty";
import { WEEK_DAYS_OBJ } from "../../../utils/dictionaries";
import { IScheduleRequestData } from "../Hooks/useRequestData";
import dayjs from "dayjs";
import localePl from "dayjs/locale/pl";
import FormControlLabel from "rsuite/FormControlLabel";
import { useParams } from "react-router-dom";
import { IScheduleDynamicForm } from "../Hooks/useForm";
import SeparatorLine from "../../../global/atoms/separators/SeparatorLine";
import toastNotification, {
  ToastTypes,
} from "../../../global/ToastNotification";
import FormErrorMessage from "rsuite/FormErrorMessage";
import SendIcon from "@rsuite/icons/Send";
import SpinnerIcon from "@rsuite/icons/legacy/Spinner";
import customParseFormat from "dayjs/plugin/customParseFormat";
import SchedulesConnection from "../../../utils/connections/schedules";

dayjs.locale(localePl);
dayjs.extend(customParseFormat);

const SINGLE_DAY_INDEX = -1;
const TIME_NUMBER = "HOURS_EXPLICITLY";
const TIME_STRING = "TIME";
const DEFAULT_DAY = {
  day: SINGLE_DAY_INDEX,
  hours: undefined,
  start: undefined,
  end: undefined,
  type: TIME_NUMBER,
};

interface IScheduleModalBatchAdd {
  onModalSubmit: (data: IScheduleRequestData) => void;
  requestData: IScheduleRequestData;
  open: boolean;
  setOpen: (open: boolean) => void;
  modalConfig?: IModalBatchAddData;
  form: IScheduleDynamicForm | null;
}

export interface IScheduleModalData {
  type: ScheduleInterval;
  startDate: Date | null;
  endDate: Date | null | undefined;
  days: Array<number>;
  projectId: string;
  networkId: string;
  locations: Array<string>;
  taskId: string;
  userId: string;
  hours: number | null;
  users?: Array<IUserSimple>;
  frequency?: number;
  avoidDuplicates?: boolean;
}

interface IHourData {
  day?: number;
  hours?: number | null;
  start?: string | null;
  end?: string | null;
  type: string;
}

const ScheduleModalBatchAdd: FunctionComponent<IScheduleModalBatchAdd> = ({
  requestData,
  form,
  ...props
}) => {
  const { id } = useParams<{ id: string }>();

  //@ts-ignore
  const initialModalData: IScheduleModalData = {
    ...requestData,
    projectId: id,
    type: ScheduleInterval.Single,
    days: [dayjs(props?.modalConfig?.date ?? new Date()).day()],
    hours: null,
    startDate: props?.modalConfig?.date ?? new Date(),
    endDate: undefined,
    frequency: ScheduleInterval.Weekly,
  };

  const [modalData, setModalData] =
    useState<IScheduleModalData>(initialModalData);

  const [hoursData, setHoursData] = useState<Array<IHourData> | null>([
    DEFAULT_DAY,
  ]);
  const [hoursDataSingle, setHoursDataSingle] = useState<IHourData | null>(
    DEFAULT_DAY
  );

  const [errors, setErrors] = useState<any>();
  const [isWorking, setIsWorking] = useState<boolean>(false);
  const getFiltersName = () => {
    const names: Array<string> = [];
    let locationsName = form?.locations
      ?.filter((l) => (modalData?.locations ?? []).includes(l.id))
      .map((l) => l.name)
      .join(", ");
    if (!locationsName) locationsName = `(wszystkie)`;
    names.push(`Lokalizacje: ${locationsName}`);
    const userName =
      form?.users?.find((u) => modalData?.userId == u.id)?.name ?? `(wszyscy)`;
    names.push(`Wykonawca: ${userName}`);
    const taskName =
      form?.tasks?.find((t) => modalData?.taskId == t.id)?.name ??
      `(wszystkie)`;
    names.push(`Zadanie: ${taskName}`);
    const networkName =
      form?.networks?.find((n) => modalData?.networkId == n.id)?.name ??
      `(wszystkie)`;
    names.push(`Sieć: ${networkName}`);
    return names;
  };

  const intervals = {
    [ScheduleInterval.Weekly]: "Raz na tydzień",
    [ScheduleInterval.Weekly2]: "Raz na 2 tygodnie",
    [ScheduleInterval.Weekly3]: "Raz na 3 tygodnie",
    [ScheduleInterval.Weekly4]: "Raz na cztery tygodnie",
  };

  const isReadyToSave = (): boolean => {
    if (!modalData) {
      toastNotification(ToastTypes.info, "Dane niekompletne");
      return false;
    }
    if (modalData.type !== ScheduleInterval.Single) {
      // recur schedule
      if (!modalData.days?.length) {
        toastNotification(ToastTypes.info, "Wybierz dni tygodnia");
        return false; // no-days selected in recur schedule
      }
      if (!modalData.startDate) {
        toastNotification(ToastTypes.info, "Wybierz datę początkową");
        return false; // empty start date
      }
    }
    return true;
  };
  const hourDay = (dayNumber: number): IHourData | undefined => {
    return hoursData?.find((d) => d.day == dayNumber);
  };
  const getTimeToDate = (dayKey: number, field: string) => {
    const day = hourDay(dayKey);
    const value = day?.[field];
    return value ? dayjs(value, "HH:mm").toDate() : undefined;
  };
  const isNumberRange = (dayNumber: number) => {
    const day = hourDay(dayNumber);
    return day?.type != TIME_STRING;
  };
  const filteredLocations = () => {
    return (form?.locations ?? []).filter(
      (l) =>
        (modalData.projectId
          ? l.projects.includes(modalData.projectId)
          : true) &&
        (modalData.networkId ? l.networks.includes(modalData.networkId) : true)
    );
  };
  const filteredTasks = () => {
    return (form?.tasks ?? []).filter((t) =>
      modalData.projectId ? t.projects.includes(modalData.projectId) : true
    );
  };

  const updateState = (value: Partial<IScheduleModalData>) => {
    setModalData((s) => {
      if (!s) return s;
      return { ...s, ...value };
    });
  };

  const handleBatchCreate = () => {
    let days: Array<IHourData> = [];
    if (modalData?.type == ScheduleInterval.Single) {
      const day = hoursDataSingle;
      if (!day) {
        return;
      }
      day.day = 0;
      if (day?.type == TIME_NUMBER) {
        day.start = null;
        day.end = null;
      } else if (day?.type == TIME_STRING) {
        day.hours = null;
      }
      if (day) {
        days = [day];
      }
    } else {
      days =
        hoursData?.filter(
          (h) =>
            h.day != SINGLE_DAY_INDEX &&
            modalData.days.includes(h.day ?? SINGLE_DAY_INDEX)
        ) ?? [];
    }
    setIsWorking(true);
    SchedulesConnection.batchCreate({
      startDate: modalData.startDate,
      endDate: modalData.endDate,
      frequency: modalData.frequency,
      days: modalData.days,
      filter: {
        ...requestData,
        projectId: modalData.projectId,
        taskId: modalData.taskId,
        networkId: modalData.networkId,
        locations: modalData.locations,
        userId: modalData.userId,
      },
      hours: days,
      avoidDuplicates: modalData.avoidDuplicates,
    })
      .then((resp) => {
        if (resp.status == 201) {
          toastNotification(
            ToastTypes.loading,
            "Wpisy w harmonogramie utworzone"
          );
          props.onModalSubmit(requestData);
          setIsWorking(false);
          props.setOpen(false);
        }
      })
      .catch((error) => {
        setErrors({
          ...error?.response?.data?.errors,
          ...error?.response?.data?.errors?.filters,
        });
        setIsWorking(false);
      });
  };

  return (
    <>
      <Modal open={props.open} size={"lg"} onClose={() => props.setOpen(false)}>
        <Modal.Header>
          <h6>
            {props.modalConfig?.type === "batchAdd" &&
              "Nowy harmonogram (wielokrotne wpisy)"}
          </h6>
        </Modal.Header>

        <Modal.Body>
          <Nav
            appearance="default"
            justified
            onSelect={(eventKey: string) =>
              updateState({
                type:
                  eventKey === "single"
                    ? ScheduleInterval.Single
                    : ScheduleInterval.Weekly,
              })
            }>
            <Nav.Item
              eventKey={"single"}
              active={modalData.type === ScheduleInterval.Single}>
              Jednorazowy
            </Nav.Item>
            <Nav.Item
              eventKey={"recurrent"}
              active={modalData.type !== ScheduleInterval.Single}>
              Cykliczny
            </Nav.Item>
          </Nav>
          <SeparatorEmpty />
          <Col xs={12}>
            <FormControlLabel>Projekt</FormControlLabel>
            <SelectPicker
              readOnly={true}
              disabled={true}
              data={form?.projects ?? []}
              value={modalData.projectId ?? undefined}
              valueKey={"id"}
              labelKey={"name"}
              cleanable={true}
              searchable={true}
              placeholder={"wybierz projekt..."}
              block
              onChange={(project) =>
                updateState({ projectId: project ?? undefined })
              }
            />
            <FormErrorMessage placement={"bottomEnd"} show={errors?.projectId}>
              {errors?.projectId}
            </FormErrorMessage>
          </Col>
          <Col xs={12}>
            <FormControlLabel>Zadanie</FormControlLabel>
            <SelectPicker
              data={filteredTasks()}
              value={modalData.taskId ?? undefined}
              valueKey={"id"}
              labelKey={"name"}
              cleanable={true}
              searchable={true}
              placeholder={"wybierz zadanie..."}
              block
              onChange={(project) =>
                updateState({ taskId: project ?? undefined })
              }
            />
            <FormErrorMessage placement={"bottomEnd"} show={errors?.taskId}>
              {errors?.taskId}
            </FormErrorMessage>
          </Col>
          <Col xs={12}>
            <FormControlLabel>Sieć</FormControlLabel>
            <SelectPicker
              data={form?.networks ?? []}
              value={modalData?.networkId ?? undefined}
              valueKey={"id"}
              labelKey={"name"}
              cleanable={true}
              searchable={true}
              placeholder={"wybierz..."}
              block
              onChange={(network) =>
                updateState({
                  networkId: network ?? undefined,
                  locations: [],
                })
              }
            />
          </Col>
          <Col xs={12}>
            <FormControlLabel>Lokalizacje</FormControlLabel>
            <TagPicker
              data={filteredLocations()}
              value={modalData.locations ?? undefined}
              valueKey={"id"}
              labelKey={"name"}
              searchable={true}
              placeholder={"wybierz"}
              block
              onChange={(locations) =>
                updateState({ locations: locations ?? undefined })
              }
            />
          </Col>

          <Col xs={12}>
            <FormControlLabel>Raportujący</FormControlLabel>
            <SelectPicker
              data={form?.users ?? []}
              value={modalData.userId ?? undefined}
              valueKey={"id"}
              labelKey={"name"}
              searchable={true}
              placeholder={"wybierz"}
              block
              onChange={(value) => updateState({ userId: value ?? undefined })}
            />
          </Col>
          <Col xs={12} style={{ marginTop: "20px" }}>
            <Checkbox
              checked={modalData.avoidDuplicates}
              onChange={(value, checked) =>
                updateState({ avoidDuplicates: checked })
              }>
              Unikaj duplikatów
            </Checkbox>
          </Col>
          <SeparatorEmpty />
          <Col xs={24} style={{ paddingLeft: "0px", paddingRight: "0px" }}>
            {/* hours for single */}
            {modalData.type === ScheduleInterval.Single && (
              <>
                <Col xs={24}>
                  <FormControlLabel>
                    <strong>Godziny</strong>
                  </FormControlLabel>
                </Col>
                <Col xs={24} style={{ display: "flex", alignItems: "center" }}>
                  <Col xs={4}>
                    <Toggle
                      size={"md"}
                      checkedChildren={"Zakres"}
                      unCheckedChildren={"Liczba"}
                      checked={hoursDataSingle?.type === TIME_STRING}
                      onChange={(checked) => {
                        setHoursDataSingle((s) => ({
                          ...s,
                          type: checked ? TIME_STRING : TIME_NUMBER,
                        }));
                      }}
                    />
                  </Col>
                  {hoursDataSingle?.type === TIME_STRING && (
                    <>
                      <Col xs={10}>
                        <DatePicker
                          size={"sm"}
                          block
                          format="HH:mm"
                          cleanable={true}
                          value={
                            hoursDataSingle.start
                              ? dayjs(hoursDataSingle.start, "HH:mm").toDate()
                              : undefined
                          }
                          onChange={(value) => {
                            setHoursDataSingle((s) => {
                              if (!s) return s;
                              return {
                                ...s,
                                start: value
                                  ? dayjs(value).format("HH:mm")
                                  : undefined,
                              };
                            });
                          }}
                          ranges={[
                            {
                              label: "8:00",
                              value: dayjs().hour(8).minute(0).toDate(),
                            },
                            {
                              label: "10:00",
                              value: dayjs().hour(10).minute(0).toDate(),
                            },
                          ]}
                        />
                      </Col>
                      <Col xs={10}>
                        <DatePicker
                          size={"sm"}
                          block
                          format="HH:mm"
                          cleanable={true}
                          value={
                            hoursDataSingle.end
                              ? dayjs(hoursDataSingle.start, "HH:mm").toDate()
                              : undefined
                          }
                          onChange={(value) => {
                            setHoursDataSingle((s) => {
                              if (!s) return s;
                              return {
                                ...s,
                                end: value
                                  ? dayjs(value).format("HH:mm")
                                  : undefined,
                              };
                            });
                          }}
                          ranges={[
                            {
                              label: "16:00",
                              value: dayjs().hour(16).minute(0).toDate(),
                            },
                            {
                              label: "18:00",
                              value: dayjs().hour(18).minute(0).toDate(),
                            },
                          ]}
                        />
                      </Col>
                    </>
                  )}
                  {hoursDataSingle?.type === TIME_NUMBER && (
                    <Col xs={20}>
                      <InputNumber
                        size={"sm"}
                        value={hoursDataSingle?.hours ?? undefined}
                        step={0.1}
                        min={0}
                        max={100}
                        onChange={(value) => {
                          setHoursDataSingle((s) => {
                            if (!s) return s;
                            return {
                              ...s,
                              hours: Number(value),
                            };
                          });
                        }}
                      />
                    </Col>
                  )}
                </Col>
              </>
            )}
          </Col>
          {modalData.type === ScheduleInterval.Single && (
            <>
              <SeparatorEmpty size={2} />
              <h5 style={{ textAlign: "center", fontWeight: "bold" }}>
                {dayjs(modalData.startDate).format("dddd, D MMMM YYYY")}
              </h5>
            </>
          )}
          {modalData.type !== ScheduleInterval.Single && (
            <>
              <Col xs={24} style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                <Col xs={24}>
                  <Col xs={12}>
                    <FormControlLabel>
                      <strong>Dni tygodnia</strong>
                    </FormControlLabel>
                  </Col>
                  <Col xs={12}>
                    <FormControlLabel>
                      {" "}
                      <strong>Godziny</strong>
                    </FormControlLabel>
                  </Col>
                  {WEEK_DAYS_OBJ.map((wd) => (
                    <>
                      <Col
                        xs={24}
                        style={{ display: "flex", alignItems: "center" }}>
                        <Col xs={10}>
                          <Checkbox
                            checked={(modalData.days ?? []).includes(wd.key)}
                            key={`k-${wd.key}`}
                            style={{
                              display: "block",
                              opacity: (modalData.days ?? []).includes(wd.key)
                                ? "1"
                                : "0.5",
                              padding: 0,
                            }}
                            readOnly={true}
                            onChange={(value, checked) => {
                              const daysFiltered = (
                                modalData?.days ?? []
                              ).filter((_d) => _d !== wd.key);
                              updateState({
                                days: checked
                                  ? daysFiltered.concat([wd.key])
                                  : daysFiltered,
                              });
                            }}>
                            {" "}
                            {wd.name}
                          </Checkbox>
                        </Col>
                        {(modalData.days ?? []).includes(wd.key) && (
                          <>
                            <Col xs={4}>
                              <Toggle
                                size="md"
                                checkedChildren={"Zakres"}
                                unCheckedChildren={"Liczba"}
                                checked={!isNumberRange(wd.key)}
                                onChange={(checked) => {
                                  setHoursData((s) => {
                                    const day = s?.find(
                                      (d) => d.day == wd.key
                                    ) ?? {
                                      type: TIME_STRING,
                                      day: wd.key,
                                      hours: undefined,
                                      start: undefined,
                                      end: undefined,
                                    };
                                    day.type = checked
                                      ? TIME_STRING
                                      : TIME_NUMBER;
                                    return [
                                      ...(s?.filter((h) => h.day != wd.key) ??
                                        []),
                                      day,
                                    ];
                                  });
                                }}
                              />
                            </Col>
                            {!isNumberRange(wd.key) && (
                              <>
                                <Col xs={5}>
                                  <DatePicker
                                    block
                                    size={"sm"}
                                    format="HH:mm"
                                    cleanable={true}
                                    value={getTimeToDate(wd.key, "start")}
                                    onChange={(value) => {
                                      setHoursData((s) => {
                                        const day = s?.find(
                                          (d) => d.day == wd.key
                                        ) ?? {
                                          type: TIME_STRING,
                                          day: wd.key,
                                          hours: 0,
                                          start: value
                                            ? dayjs(value).format("HH:mm")
                                            : undefined,
                                        };
                                        day.start = value
                                          ? dayjs(value).format("HH:mm")
                                          : undefined;
                                        return [
                                          ...(s?.filter(
                                            (h) => h.day != wd.key
                                          ) ?? []),
                                          day,
                                        ];
                                      });
                                    }}
                                    ranges={[
                                      {
                                        label: "8:00",
                                        value: dayjs()
                                          .hour(8)
                                          .minute(0)
                                          .toDate(),
                                      },
                                      {
                                        label: "10:00",
                                        value: dayjs()
                                          .hour(10)
                                          .minute(0)
                                          .toDate(),
                                      },
                                    ]}
                                  />
                                </Col>
                                <Col xs={5}>
                                  <DatePicker
                                    block
                                    size={"sm"}
                                    format="HH:mm"
                                    cleanable={true}
                                    value={getTimeToDate(wd.key, "end")}
                                    onChange={(value) => {
                                      setHoursData((s) => {
                                        const day = s?.find(
                                          (d) => d.day == wd.key
                                        ) ?? {
                                          type: TIME_STRING,
                                          day: wd.key,
                                          hours: 0,
                                          end: value
                                            ? dayjs(value).format("HH:mm")
                                            : undefined,
                                        };
                                        day.end = value
                                          ? dayjs(value).format("HH:mm")
                                          : undefined;
                                        return [
                                          ...(s?.filter(
                                            (h) => h.day != wd.key
                                          ) ?? []),
                                          day,
                                        ];
                                      });
                                    }}
                                    ranges={[
                                      {
                                        label: "16:00",
                                        value: dayjs()
                                          .hour(16)
                                          .minute(0)
                                          .toDate(),
                                      },
                                      {
                                        label: "18:00",
                                        value: dayjs()
                                          .hour(18)
                                          .minute(0)
                                          .toDate(),
                                      },
                                    ]}
                                  />
                                </Col>
                              </>
                            )}
                            {isNumberRange(wd.key) && (
                              <Col xs={10}>
                                <InputNumber
                                  size={"sm"}
                                  value={hourDay(wd.key)?.hours ?? undefined}
                                  step={0.1}
                                  min={0}
                                  max={100}
                                  onChange={(value: number | string) => {
                                    setHoursData((s) => {
                                      const day = s?.find(
                                        (h) => h.day == wd.key
                                      ) ?? {
                                        day: wd.key,
                                        hours: undefined,
                                        start: undefined,
                                        end: undefined,
                                        type: TIME_NUMBER,
                                      };
                                      day.hours = Number(value);
                                      const days =
                                        s?.filter((h) => h.day != wd.key) ?? [];
                                      return [...days, day];
                                    });
                                  }}
                                />
                              </Col>
                            )}
                          </>
                        )}
                      </Col>
                    </>
                  ))}
                </Col>
                <SeparatorEmpty />
                <Col xs={24}>
                  <FormControlLabel>Częstotliwość</FormControlLabel>
                  <SelectPicker
                    data={Object.entries(intervals).map((v) => ({
                      label: v[1],
                      value: parseInt(v[0]),
                    }))}
                    block
                    value={modalData.frequency}
                    cleanable={false}
                    searchable={false}
                    placeholder={"wybierz"}
                    onChange={(value) =>
                      updateState({
                        frequency: value
                          ? parseInt(value.toString())
                          : undefined,
                      })
                    }
                  />
                  <FormErrorMessage
                    placement={"bottomEnd"}
                    show={errors?.frequency}>
                    {errors?.frequency}
                  </FormErrorMessage>
                </Col>
                <SeparatorEmpty />
                <Col
                  xs={12}
                  style={{ marginBottom: "10px", marginTop: "10px" }}>
                  <strong>
                    Harmonogram od - do&nbsp;&nbsp;
                    <Toggle
                      size="md"
                      checkedChildren={"∞"}
                      unCheckedChildren={"data końcowa"}
                      checked={modalData.endDate === null}
                      onChange={(val) => {
                        updateState({ endDate: !val ? undefined : null });
                      }}
                    />
                  </strong>
                </Col>
                <Col
                  xs={12}
                  style={{
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    marginBottom: "10px",
                  }}>
                  <Col xs={12}>
                    <DatePicker
                      readOnly={true}
                      oneTap
                      block
                      placement={"topStart"}
                      cleanable={false}
                      isoWeek
                      shouldDisableDate={(date) =>
                        dayjs(date).isBefore(dayjs())
                      }
                      onChange={(date) => updateState({ startDate: date })}
                      value={modalData.startDate ?? undefined}
                    />
                  </Col>
                  {modalData.endDate !== null && (
                    <Col xs={12}>
                      <DatePicker
                        block
                        oneTap
                        placement={"topStart"}
                        isoWeek
                        cleanable={false}
                        shouldDisableDate={(date) =>
                          dayjs(date).isBefore(modalData.startDate ?? dayjs())
                        }
                        onChange={(date) => updateState({ endDate: date })}
                        value={modalData.endDate}
                      />
                    </Col>
                  )}
                  {modalData.endDate === null && (
                    <Col xs={12}>
                      <div
                        style={{
                          textAlign: "center",
                          marginTop: "8px",
                          color: "#a2a2a2",
                        }}>
                        Brak daty końcowej
                      </div>
                    </Col>
                  )}
                </Col>
              </Col>
            </>
          )}
        </Modal.Body>

        <Modal.Footer style={{ marginTop: "10px" }}>
          <SeparatorLine />
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Message
              showIcon
              header={"Uwaga!"}
              type={"info"}
              style={{ width: "75%", textAlign: "left" }}>
              {getFiltersName().map((p) => (
                <>
                  <span>{p}</span>
                </>
              ))}
            </Message>
            <div>
              <IconButton
                icon={isWorking ? <SpinnerIcon spin={true} /> : <SendIcon />}
                appearance={isWorking ? "link" : "ghost"}
                disabled={!isReadyToSave() || isWorking}
                onClick={handleBatchCreate}>
                Utwórz
              </IconButton>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ScheduleModalBatchAdd;
