import React, { FunctionComponent } from "react";
import DoubleSideThinPicker, {
  IDoubleSidePicker,
  IFilterForm,
} from "./DoubleSideThinPicker";
import BaseThinItemBody from "./itemBody/BaseThinItemBody";
import BasicThinFilter from "./itemFilter/BasicThinFilter";
const ItemDoublePicker: FunctionComponent<IDoubleSidePicker> & {
  Item: typeof BaseThinItemBody;
} & { Filter: typeof BasicThinFilter } = (props) => {
  return <DoubleSideThinPicker {...props} />;
};

export type { IFilterForm };

ItemDoublePicker.Item = BaseThinItemBody;
ItemDoublePicker.Filter = BasicThinFilter;

export default ItemDoublePicker;
