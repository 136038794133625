import React, { useState } from "react";
import { FunctionComponent } from "react";
import TargetConnections from "./TargetConnections";
import { _paginate30 } from "../../../../utils/states";
import { IHeader, IPagination, ISort } from "../../../../utils/models";
import { generatePath, useParams } from "react-router-dom";
import Pagination from "../../../../global/pagination/Pagination";
import UniversalFilters, {
  FILTER_INPUT_TEXT,
} from "../../../../global/filters/UniversalFilters";
import { handleSortColumn } from "../../../../utils/helpers";

import PaginatedTableViewWrapper from "../../../../global/PaginatedTableViewWrapper";
import Table from "rsuite/Table";

import ButtonListRedirect from "../../../../global/atoms/ButtonListRedirect";
import styles from "../warehouse/styles.module.scss";

export interface ITargetDefinitionsFilters {
  name: string;
  requestPaginate: IPagination;
  requestOrder: ISort;
}

interface ITargetDefinitionData {
  id: string;
  name: string;
  description: string;
  type: string;
  status: string;
  startDate: string;
  endDate: string;
}

interface ITargetDefinitionsData {
  columns: any;
  header: IHeader;
  data: Array<ITargetDefinitionData>;
  count: number;
}

const TargetsUsersList: FunctionComponent = () => {
  const { id } = useParams<{ id: string }>();
  const defaultFilters: ITargetDefinitionsFilters = {
    name: "",
    ..._paginate30,
  };

  const [loading, setLoading] = useState<boolean>(true);

  const [data, setData] = useState<ITargetDefinitionsData>();
  const [filters, setFilters] =
    useState<ITargetDefinitionsFilters>(defaultFilters);

  return (
    <>
      <PaginatedTableViewWrapper
        table={
          <Table
            fillHeight
            loading={loading}
            data={data?.data ?? []}
            sortColumn={filters.requestOrder.field}
            sortType={filters.requestOrder.order}
            onSortColumn={(dataKey, sortType) =>
              handleSortColumn(dataKey, sortType, setFilters)
            }>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>Pracownik</Table.HeaderCell>
              <Table.Cell dataKey="userName" />
            </Table.Column>
            <Table.Column flexGrow={2}>
              <Table.HeaderCell>
                <div>
                  <div>Nazwa</div>
                  <div className={styles.style_header}>Wygenerowany</div>
                </div>
              </Table.HeaderCell>
              <Table.Cell dataKey="targetName">
                {(rowData) => (
                  <>
                    {" "}
                    <ButtonListRedirect
                      redirectUrl={generatePath(
                        "/projects/:id/targets/:targetId",
                        {
                          id: id,
                          targetId: rowData.targetId,
                        }
                      )}>
                      {rowData.targetName}
                    </ButtonListRedirect>
                    <div
                      style={{
                        position: "absolute",
                        display: "block",
                        color: "rgba(134,134,134,0.48)",
                        fontSize: "0.7em",
                        marginTop: "5px",
                        marginBottom: "5px",
                      }}>
                      {rowData.updatedAt}
                    </div>
                  </>
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>Target</Table.HeaderCell>
              <Table.Cell dataKey="value" />
            </Table.Column>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>Realizacja</Table.HeaderCell>
              <Table.Cell dataKey="achievement" />
            </Table.Column>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>Aktywne od</Table.HeaderCell>
              <Table.Cell dataKey="startDate" />
            </Table.Column>
            <Table.Column flexGrow={1}>
              <Table.HeaderCell>Aktywne do</Table.HeaderCell>
              <Table.Cell dataKey="endDate" />
            </Table.Column>
            <Table.Column flexGrow={2}>
              <Table.HeaderCell>Typ</Table.HeaderCell>
              <Table.Cell dataKey="type.name" />
            </Table.Column>
            <Table.Column flexGrow={1} fixed={"right"}>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.Cell dataKey="status.name" />
            </Table.Column>
          </Table>
        }
        pagination={
          <Pagination
            count={data?.count ?? 0}
            limit={filters.requestPaginate.limit.toString()}
            page={filters.requestPaginate.page.toString()}
            setState={setFilters}
          />
        }
      />
      <UniversalFilters
        load={TargetConnections.getTargetUsersList.bind(null, id)}
        formGet={TargetConnections.getTargetUsersFilterForm.bind(null, id)}
        setLoading={setLoading}
        state={filters}
        setState={setFilters}
        defaultStateData={defaultFilters}
        setResultData={setData}
        filterStorageKey={`projectsOrdersList_${id}`}
        elements={[
          {
            type: FILTER_INPUT_TEXT,
            stateKey: "name",
            label: "Nazwa",
          },
        ]}
      />
    </>
  );
};

export default TargetsUsersList;
