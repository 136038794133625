import { useState } from "react";
import SchedulesConnection from "utils/connections/schedules";
import { IScheduleItem } from "../Schedule";
import { IScheduleRequestData } from "./useRequestData";
import { NotCancelErrorPromise } from "utils/helpers";

export interface IScheduleData {
  data: Array<IScheduleItem>;
  disabledElements: string[];
  hoursStrategy?: string;
}

function useScheduleData(): [
  IScheduleData | null,
  (
    requestData: IScheduleRequestData,
    loadMore?: boolean,
    callback?: () => void
  ) => void,
  boolean,
  boolean,
] {
  const [scheduleData, setScheduleData] = useState<IScheduleData | null>(null);
  const [dataLoading, setDataLoading] = useState(false);
  const [fetchedAll, setFetchedAll] = useState(false);

  const loadData = (
    requestData: IScheduleRequestData,
    loadMore?: boolean,
    callback?: () => void
  ) => {
    setDataLoading(true);
    SchedulesConnection.pivotList(requestData).then((res) => {
      if (loadMore) {
        setScheduleData((sd) => ({
          disabledElements: sd?.disabledElements ?? [],
          data: [...(scheduleData?.data ?? []), ...res.data.data],
        }));
      } else setScheduleData(res.data);

      setFetchedAll(
        (res.data.data.length ?? 0) < requestData.requestPaginate.limit
      );
      setDataLoading(false);
      if (callback) callback();
    }, NotCancelErrorPromise);
  };

  return [scheduleData, loadData, dataLoading, fetchedAll];
}

export default useScheduleData;
