import React, { useState } from "react";

import { Col, Form, IconButton, InputGroup } from "rsuite";
import FunnelIcon from "@rsuite/icons/Funnel";
import SeparatorEmpty from "../../separators/SeparatorEmpty";
import { FCC } from "../../../../utils/models";
import CloseIcon from "@rsuite/icons/Close";

interface IThinFilter {
  [name: string]: string;
}

interface IBasicThinFilter {
  filter: IThinFilter | null;
  setFilter: React.Dispatch<React.SetStateAction<IThinFilter | null>>;
}

const BasicThinFilter: FCC<IBasicThinFilter> = (props) => {
  const [isFilterActive, setIsFilterActive] = useState(false);
  const formSize = "md";
  return (
    <>
      <Form fluid>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
          }}>
          <div style={{ display: "flex", gap: "10px", width: "100%" }}>
            <Col xs={12}>
              <InputGroup style={{ width: "100%" }}>
                <Form.Control
                  size={formSize}
                  name={"textSearch"}
                  value={props.filter?.textSearch ?? ""}
                  placeholder={"Wyszukaj..."}
                  onChange={(value) => {
                    props.setFilter((s) => ({ ...s, textSearch: value }));
                  }}
                />
                {props?.filter?.textSearch && (
                  <InputGroup.Addon>
                    <CloseIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        props.setFilter((s) => ({ ...s, textSearch: "" }));
                      }}
                    />
                  </InputGroup.Addon>
                )}
              </InputGroup>
            </Col>
            <Col xs={4}>
              {props.children && (
                <IconButton
                  icon={isFilterActive ? <CloseIcon /> : <FunnelIcon />}
                  size={formSize}
                  appearance={isFilterActive ? "primary" : "default"}
                  onClick={() => {
                    isFilterActive && props.setFilter(() => null);
                    setIsFilterActive(!isFilterActive);
                  }}>
                  Filtry
                </IconButton>
              )}
            </Col>
          </div>
          {isFilterActive && props.children}
        </div>
        <SeparatorEmpty />
      </Form>
    </>
  );
};

export default BasicThinFilter;
