import React, { FunctionComponent, useEffect, useState } from "react";
import TasksConnection from "utils/connections/tasks";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { getProjectTasksState } from "utils/states";
import { IGetProjectTasks, ITask } from "utils/models";
import Pagination from "global/pagination/Pagination";
import { IconButton, Table } from "rsuite";
import HeaderButtons from "global/atoms/headerButtons/HeaderButtons";
import ButtonAddNew from "global/atoms/ButtonAddNew";

import ActionsContainer from "../../../../global/atoms/ActionsContainer";
import AIconButton from "../../../../global/atoms/IconButton";
import BadgeStatus from "../../../../global/atoms/badge/BadgeStatus";
import { DeleteSingleButton } from "../../../../utils/toggleToDeleteHelper";

import { getProjectIdFromUrl, handleSortColumn } from "utils/helpers";
import CopyIcon from "@rsuite/icons/Copy";
import WaitIcon from "@rsuite/icons/Wait";
import ConversionIcon from "@rsuite/icons/Conversion";
import { ToastNotificationPush, ToastTypes } from "global/ToastNotification";
import CloneTaskModal from "../cloneModal/CloneTaskModal";
import CloseOutlineIcon from "@rsuite/icons/CloseOutline";
import CheckOutlineIcon from "@rsuite/icons/CheckOutline";
import PaginatedTableViewWrapper from "../../../../global/PaginatedTableViewWrapper";
import ButtonListRedirect from "../../../../global/atoms/ButtonListRedirect";
import TableColumnIcon from "@rsuite/icons/TableColumn";
import TasksListSortable from "./TasksListSortable";
import UniversalFilters, {
  FILTER_INPUT_TEXT,
} from "../../../../global/filters/UniversalFilters";

const INTERVAL_UPDATE = 10000;

interface ITasksList {}

const TasksList: FunctionComponent<ITasksList> = () => {
  const { id } = useParams<{ id: string }>();
  const [data, setData] = useState<Array<ITask>>([]);
  const [count, setCount] = useState(0);

  const [form, setForm] = useState<any>({});
  const history = useHistory();
  const [triggerLoad, setTriggerLoad] = useState(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [sortableActive, setSortableActive] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [cloneData, setCloneData] = useState();
  const [isInProgres, setIsInProgres] = useState(false);

  const [filters, setFilters] =
    useState<IGetProjectTasks>(getProjectTasksState);
  const handleGoToEdit = (taskId: string) => {
    history.push(
      generatePath("/projects/:id/tasks/:taskId/edit", {
        id: id,
        taskId: taskId,
      })
    );
  };

  const removeTask = (taskId: string, taskName: string) => {
    TasksConnection.deleteTask(id, taskId).then(() => {
      ToastNotificationPush(
        ToastTypes.success,
        `Usunąłeś zadanie o id: ${taskName}`
      );
      setTriggerLoad(Date.now());
    });
  };
  const copyModalOpen = (task) => {
    if (task) {
      setCloneData(task);
      setIsModalOpen(true);
    }
  };

  const handleRefreshingAction = () => {
    setIsInProgres(true);
    TasksConnection.refreshingAction().then((resp) => {
      console.log(resp);
    });
  };
  const isEnabledButton = () => {
    return (
      !isInProgres &&
      !(form?.disabledElements?.includes("refreshVisitsButton") ?? true)
    );
  };
  const getIsInProgres = () => {
    return isInProgres;
  };

  useEffect(() => {
    const intervalUpdate = setInterval(() => {
      if (isInProgres) {
        TasksConnection.getFiltersForTasks().then((resp) => {
          console.log(resp.data);
          setForm((f) => ({ ...f, ...resp.data }));
          setIsInProgres(resp.data.refreshingInProgress);
        });
      }
    }, INTERVAL_UPDATE);

    return () => {
      clearInterval(intervalUpdate);
    };
  }, []);

  return (
    <>
      <HeaderButtons>
        <IconButton
          appearance={"ghost"}
          onClick={() => setSortableActive(true)}
          icon={<TableColumnIcon />}>
          Sortowanie
        </IconButton>
        {isEnabledButton() && (
          <IconButton
            appearance={"ghost"}
            disabled={getIsInProgres()}
            icon={getIsInProgres() ? <WaitIcon /> : <ConversionIcon />}
            onClick={handleRefreshingAction}>
            Zaktualizuj pytania w wizytach
          </IconButton>
        )}
        <ButtonAddNew form={form} />
      </HeaderButtons>
      <PaginatedTableViewWrapper
        table={
          <Table
            loading={loading}
            fillHeight
            hover={false}
            data={data ?? []}
            sortColumn={filters.requestOrder.field}
            sortType={filters.requestOrder.order}
            onSortColumn={(dataKey, sortType) =>
              handleSortColumn(dataKey, sortType, setFilters)
            }>
            <Table.Column
              flexGrow={1}
              minWidth={200}
              align="left"
              sortable
              resizable>
              <Table.HeaderCell>Nazwa</Table.HeaderCell>
              <Table.Cell dataKey="name">
                {(rowData) => (
                  <ButtonListRedirect
                    redirectUrl={`/projects/${id}/tasks/${rowData.id}`}>
                    {rowData.name}
                  </ButtonListRedirect>
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column width={150} align="center" sortable resizable>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.Cell dataKey="taskStatus">
                {(rowData) => (
                  <BadgeStatus color={rowData.taskStatus.color}>
                    {rowData.taskStatus.name}
                  </BadgeStatus>
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column width={150} align="center" resizable sortable>
              <Table.HeaderCell title={"Zadanie aktywne od"}>
                Aktywne od
              </Table.HeaderCell>
              <Table.Cell dataKey="scheduleDate" />
            </Table.Column>
            <Table.Column width={150} align="center" resizable sortable>
              <Table.HeaderCell title={"Zadanie aktywne od"}>
                Aktywne do
              </Table.HeaderCell>
              <Table.Cell dataKey="endDate" />
            </Table.Column>
            <Table.Column width={75} align="center">
              <Table.HeaderCell title={"Lokalizacje"}>
                Lokalizacje
              </Table.HeaderCell>
              <Table.Cell dataKey="locationsCount" />
            </Table.Column>

            <Table.Column width={75} align="center">
              <Table.HeaderCell title={"Użytkownicy"}>
                Użytkownicy
              </Table.HeaderCell>
              <Table.Cell dataKey="usersCount" />
            </Table.Column>
            <Table.Column width={75} align="right">
              <Table.HeaderCell title={"Okres ważności [dni]"}>
                Okres ważności [dni]
              </Table.HeaderCell>
              <Table.Cell
                dataKey="expirationDays"
                title={"Okres ważności [dni]"}
              />
            </Table.Column>
            <Table.Column width={75} align="center">
              <Table.HeaderCell title={"Zakończ na koniec tygodnia"}>
                Zakończ na koniec tygodnia
              </Table.HeaderCell>
              <Table.Cell
                dataKey="isExpirationEndOfWeek"
                title={"Zakończ na koniec tygodnia"}>
                {(rowData) => (
                  <>
                    {rowData.isExpirationEndOfWeek ? (
                      <CheckOutlineIcon
                        width={"15px"}
                        height={"15px"}
                        style={{
                          color: "#e09616",
                        }}
                      />
                    ) : (
                      <CloseOutlineIcon
                        width={"15px"}
                        height={"15px"}
                        style={{
                          color: "#e09616",
                        }}
                      />
                    )}
                  </>
                )}
              </Table.Cell>
            </Table.Column>
            <Table.Column width={50} resizable sortable>
              <Table.HeaderCell title={"Numer: Kolejność zadań"}>
                Numer
              </Table.HeaderCell>
              <Table.Cell dataKey="number" />
            </Table.Column>
            <Table.Column
              width={120}
              verticalAlign={"middle"}
              resizable={true}
              align="right"
              fixed={"right"}>
              <Table.HeaderCell>Akcje</Table.HeaderCell>
              <Table.Cell>
                {(rowData) => (
                  <ActionsContainer gapSize={5}>
                    <CopyIcon
                      width={"20px"}
                      height={"20px"}
                      style={{
                        cursor: "pointer",
                        color: "#e09616",
                      }}
                      onClick={copyModalOpen.bind(null, rowData)}
                    />
                    <AIconButton
                      icon={"settings"}
                      onClick={handleGoToEdit.bind(null, rowData.id)}
                    />
                    <DeleteSingleButton
                      callback={removeTask.bind(null, rowData.id, rowData.name)}
                      message={
                        "Usunięcie zadania spowoduje usunięcie powiązanych z zadaniem wpisów w harmonogramie"
                      }
                      form={form}
                    />
                  </ActionsContainer>
                )}
              </Table.Cell>
            </Table.Column>
          </Table>
        }
        pagination={
          <Pagination
            count={count}
            page={filters.requestPaginate.page.toString()}
            limit={filters.requestPaginate.limit.toString()}
            setState={setFilters}
          />
        }
      />
      <UniversalFilters
        load={TasksConnection.getTasksForProject}
        formGet={TasksConnection.getFiltersForTasks}
        setLoading={setLoading}
        state={filters}
        setState={setFilters}
        defaultStateData={getProjectTasksState}
        filterStorageKey={`projectTasks_${getProjectIdFromUrl()}`}
        getFormCallback={(data) => {
          setForm(data);
        }}
        setResultData={(resp) => {
          setData(resp.data);
          setCount(resp.count);
          setLoading(false);
        }}
        triggerLoad={triggerLoad}
        elements={[
          {
            type: FILTER_INPUT_TEXT,
            label: "Nazwa",
            stateKey: "name",
          },
        ]}
      />
      <CloneTaskModal
        open={isModalOpen}
        projectId={getProjectIdFromUrl() ?? ""}
        data={cloneData ?? { id: "", name: "" }}
        handleClose={(reload: boolean) => {
          setIsModalOpen(false);
          if (reload) setTriggerLoad(Date.now());
        }}
      />
      <TasksListSortable
        sortableActive={sortableActive}
        setSortableActive={setSortableActive}
        goRefresh={() => setTriggerLoad(Date.now())}
      />
    </>
  );
};

export default TasksList;
