import { Form, Message, Tooltip, Whisper } from "rsuite";
import { FileType } from "rsuite/esm/Uploader";
import styles from "./styles.module.scss";
import VisitComments from "./VisitElements/VisitComments";
import SeparatorEmpty from "global/atoms/separators/SeparatorEmpty";
import VisitQuestionItems from "./VisitElements/VisitQuestionItems";
import React, {
  FormEvent,
  PropsWithChildren,
  memo,
  useEffect,
  useRef,
  JSX,
} from "react";
import { cloneDeep, isArray, isEmpty } from "lodash";
import { TypeVisitStatus } from "utils/types";
import InstancesConnection from "utils/connections/instances";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { validate } from "uuid";
import {
  BOOLEAN_QUESTION,
  DATE_QUESTION,
  DECIMAL_QUESTION,
  DICTIONARY_MULTIPLE_ANSWERS_QUESTION,
  DICTIONARY_QUESTION,
  INTEGER_QUESTION,
  PHOTO_QUESTION,
  TEXTAREA_QUESTION,
  TEXT_QUESTION,
  TIME_QUESTION,
} from "./VisitElements/QuestionTypeConsts";
import { FCC, IQuestionItem } from "utils/models";
import { ToastNotificationPush, ToastTypes } from "global/ToastNotification";
import {
  parseToDate,
  questionValuesRate,
  useEditContext,
  useErrorContext,
  useFormStateContext,
  usePhotoContext,
  useStaticContext,
} from "./VisitElements/VisitHelpers";
import { isProjectScope } from "utils/helpers";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { IRoot } from "../../../../../redux/models";

export function PanelErrorsMsg() {
  return (
    <Message showIcon type="error">
      Ta sekcja zawiera błędy!
    </Message>
  );
}

export function PanelNameErrorWrapper(
  props: PropsWithChildren<{ hasErrors: boolean }>
): JSX.Element {
  if (props.hasErrors)
    return (
      <Whisper
        speaker={<Tooltip>Ta sekcja zawiera błędy!</Tooltip>}
        placement="topStart">
        <div className={styles.panelNameErrorHasError}>
          <>{props.children}</>
        </div>
      </Whisper>
    );
  return <div>{props.children}</div>;
}

export type RawValue =
  | string
  | number
  | string[]
  | null
  | undefined
  | Array<FileType>;

interface IResultComment {
  comment: string;
  toImprove: boolean; // visit changing status to improve after comment apply
  images: Array<string>;
  refQuestionId?: string;
}

interface IUnifiedQuestionValue {
  comment: string;
  lat?: null | number | string;
  lon?: null | number | string;
  name: string;
  refQuestionAnswerId: string; // uuid
  value: string | number;
  rate: number | null;
}

interface IResultQuestion {
  type: "location" | "general";
  refQuestionId: string;
  isRequired: boolean;
  values: Array<IUnifiedQuestionValue>;
  rate: number | null;
}

interface IResult {
  comments: Array<IResultComment>;
  locationQuestions: Array<IResultQuestion>;
  questions: Array<IResultQuestion>;
  newStatus: TypeVisitStatus | undefined;
}

const VisitEditForm: FCC<{
  setFormState: (formData) => void;
  readOnly?: boolean;
}> = (props) => {
  const history = useHistory();
  const { id, visitId } = useParams<{ id: string; visitId: string }>();
  const { setLoading, newStatus } = useEditContext();
  const { locationRefIds, questionsFlatState, isEnableVisitRate } =
    useStaticContext();
  const { setErrors } = useErrorContext();
  const formState = useFormStateContext();
  const { visitPhotos } = usePhotoContext();

  const { disabledQuestions } = useEditContext();
  function getQuestionPrimitiveValue(
    question: IQuestionItem
  ): boolean | string | number | null {
    const questionType = question.questionType;
    const v = question?.values;
    if (!v || !v.length || !questionType) return null;

    let val: boolean | string | number | null;

    switch (questionType as string) {
      case BOOLEAN_QUESTION:
        val = v[0].value === "1";
        break;
      case INTEGER_QUESTION:
      case DECIMAL_QUESTION:
        val = v[0].value.split(",").join(".");
        break;
      case DATE_QUESTION:
      case TIME_QUESTION:
      case TEXTAREA_QUESTION:
      case TEXT_QUESTION:
        // @ts-ignore
        val = v[0].value;
        break;
      default:
        // @ts-ignore
        val = v[0].value;
        break;
    }

    return val ?? null;
  }

  // initial value apply
  useEffect(() => {
    const tmp = {} as { [refQuestionId: string]: any };
    Object.values(questionsFlatState ?? {}).forEach((question) => {
      switch (question.questionType) {
        case TEXT_QUESTION: {
          tmp[question.refQuestionId] =
            getQuestionPrimitiveValue(question) ?? "";
          break;
        }
        case PHOTO_QUESTION: {
          // tmp[question.refQuestionId] = [];
          break;
        }
        case BOOLEAN_QUESTION: {
          const pval = getQuestionPrimitiveValue(question);
          tmp[question.refQuestionId] =
            pval === true ? "1" : pval === false ? "0" : undefined;
          break;
        }
        case DICTIONARY_MULTIPLE_ANSWERS_QUESTION: {
          const values = question.values.map((v) => v.value);
          tmp[question.refQuestionId] = values.length
            ? values
            : question?.options
                ?.filter((o) => o.isDefault)
                .map((o) => o.value) ?? null;
          break;
        }
        case DICTIONARY_QUESTION: {
          const values = question.values.map((v) => v.value);
          tmp[question.refQuestionId] =
            values?.["0"] ??
            question?.options?.find((o) => o.isDefault)?.value ??
            null;
          break;
        }
        case DATE_QUESTION: {
          const primitiveValue = getQuestionPrimitiveValue(question) as string;
          tmp[question.refQuestionId] = parseToDate(primitiveValue);
          break;
        }
        case TIME_QUESTION: {
          const value = getQuestionPrimitiveValue(question) as string;
          let date: Date | null = null;
          if (value) {
            date = new Date();
            date.setHours(parseInt(value.split(":")[0]));
            date.setMinutes(parseInt(value.split(":")[1]));
          }

          tmp[question.refQuestionId] = date;
          break;
        }
        case TEXTAREA_QUESTION: {
          tmp[question.refQuestionId] =
            getQuestionPrimitiveValue(question) ?? ("" as string);
          break;
        }
        default: {
          tmp[question.refQuestionId] = getQuestionPrimitiveValue(question);
          break;
        }
      }
    });
    props.setFormState((fs) => ({ ...fs, ...tmp }));
    //props.onFormChange(tmp);
  }, [questionsFlatState]);

  const validationBufferTimeout = useRef<NodeJS.Timeout>();
  const triggerFormValidation = (_formState: { [name: string]: RawValue }) => {
    if (validationBufferTimeout.current)
      clearTimeout(validationBufferTimeout.current);

    setLoading(true);
    validationBufferTimeout.current = setTimeout(() => {
      InstancesConnection.validateVisit(
        visitId,
        parseFormData(formState as { [p: string]: RawValue })
      )
        .then(() => {
          setLoading(false);
          setErrors({});
        })
        .catch((res) => {
          setLoading(false);
          setErrors(res?.response?.data?.errors);
        });
    }, 1000);
  };
  const parseVisitQuestionRate = (
    rawValue: RawValue,
    question: IQuestionItem
  ): number | null => {
    if (!rawValue) return null;
    if (!isEnableVisitRate) return null;
    return questionValuesRate(question, rawValue);
  };

  const parseFormData = (_formState: { [name: string]: RawValue }): IResult => {
    const result: IResult = {
      comments:
        "visitComment" in _formState
          ? [
              {
                images: [],
                comment: String(_formState.visitComment),
                toImprove: false,
              },
            ]
          : ([] as IResultComment[]),
      questions: [],
      locationQuestions: [],
      newStatus: newStatus?.status,
    };

    const questionsData: IResultQuestion[] = [];

    //TODO move to server
    const parseValue = (
      rawValue: RawValue,
      type: string,
      refID: string
    ): IUnifiedQuestionValue[] => {
      const parseRawValue = (
        rawValue: string | number,
        type: string
      ): string | number => {
        if (type === TIME_QUESTION) {
          return rawValue ? dayjs(rawValue).format("HH:mm") : "";
        } else if (type === DATE_QUESTION) {
          if (
            typeof rawValue == "object" ||
            /^\d{4}-\d{2}-\d{2}/.test(rawValue as string) // date object or iso string
          ) {
            return (
              dayjs(rawValue)
                .unix()
                //todo remove when validation and visit save will change to int
                .toString()
            );
          }
          return rawValue; //unix time in seconds not mili seconds
        }

        return rawValue;
      };
      const isDisabled = disabledQuestions.includes(refID);
      if (isArray(rawValue)) {
        return rawValue.map((v) => {
          return {
            comment: "",
            lat: null,
            lon: null,
            name: "",
            refQuestionAnswerId: "",
            value: parseRawValue(v, type),
            rate: !isDisabled
              ? parseVisitQuestionRate(v, questionsFlatState[refID])
              : null,
          };
        });
      }
      const value = rawValue as string | number;
      return [
        {
          comment: "",
          lat: null,
          lon: null,
          name: "",
          refQuestionAnswerId: "",
          value: parseRawValue(value, type),
          rate: !isDisabled
            ? parseVisitQuestionRate(value, questionsFlatState[refID])
            : null,
        },
      ];
    };

    // parse question and comments
    Object.entries(_formState).every(([key, value]) => {
      // [0] - refQuestionId, [1] - value
      const keyparts = key.split(".");
      const refID = keyparts[0];

      // question values
      if (validate(refID)) {
        const q = questionsFlatState[refID];
        if (q?.questionType === PHOTO_QUESTION) return true; // skip photo questions
        let dataIndex = questionsData.findIndex(
          (q) => q.refQuestionId === refID
        );

        if (dataIndex < 0) {
          // question boilerplate
          questionsData.push({
            type: locationRefIds.includes(refID) ? "location" : "general",
            refQuestionId: refID,
            values: [],
            isRequired: q?.isRequired, // bullshit - to powinno byç po stronie backendu
            rate: null,
          });
          dataIndex = questionsData.length - 1;
        }
        questionsData[dataIndex].values = parseValue(
          value,
          q?.questionType,
          refID
        );
      }
      // question comments
      else if (
        keyparts[0] === "visitCommentQuestion" &&
        validate(keyparts[1])
      ) {
        result.comments.push({
          comment: String(value),
          images: [],
          toImprove: false,
          refQuestionId: keyparts[1],
        });
      }
      return true;
    });

    // parse photos
    Object.values(questionsFlatState)
      .filter((q) => q.questionType === PHOTO_QUESTION)
      .forEach((q) => {
        const isDisabled = disabledQuestions.includes(q.refQuestionId);
        const refID = q.refQuestionId;
        const values = visitPhotos.filter((vp) => vp.refQuestionId === refID);
        const rate = !isDisabled ? parseVisitQuestionRate(values, q) : null;
        values.forEach((v) => (v.rate = rate));
        questionsData.push({
          type: locationRefIds.includes(refID) ? "location" : "general",
          refQuestionId: refID,
          values: values as IUnifiedQuestionValue[],
          isRequired: q.isRequired, // bullshit - to powinno byç po stronie backendu
          rate: null,
        });
      });

    result.locationQuestions = questionsData.filter(
      (q) => q.type === "location"
    );
    result.questions = questionsData.filter((q) => q.type === "general");

    return result;
  };

  const handleSubmit = (_, event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    ToastNotificationPush(ToastTypes.loading, "Zapisywanie wizyty...");

    InstancesConnection.updateInstance(
      id,
      visitId,
      parseFormData(formState as { [name: string]: RawValue })
    )
      .then(() => {
        ToastNotificationPush(ToastTypes.info, "Wizyta została zapisana!");
        // redirect to visits list in project scope
        if (isProjectScope())
          history.push(generatePath("/projects/:id/visits", { id: id }));

        setLoading(false);
      })
      .catch((res) => {
        setLoading(false);
        ToastNotificationPush(ToastTypes.warning, "Popraw błędy w formularzu!");
        setErrors(res?.response?.data?.errors);
      });
    return false;
  };

  useEffect(() => {
    // validate form on change
    if (isProjectScope())
      triggerFormValidation(formState as { [name: string]: RawValue });
  }, [formState]);

  const onChange = (_fs: { [refQuestionId: string]: any }) => {
    props.setFormState((fs) => ({ ...fs, ..._fs }));
  };

  return (
    <Form
      fluid
      id="visit-edit-form"
      formValue={cloneDeep(formState)}
      onSubmit={handleSubmit}
      onChange={onChange}>
      <VisitComments />
      {isEmpty(questionsFlatState) && (
        <>
          <SeparatorEmpty size={1.5} />
          <Message showIcon={true} type={"warning"}>
            Brak pytań w wizycie!
          </Message>
        </>
      )}
      {!isEmpty(questionsFlatState) && <VisitQuestionItems />}
    </Form>
  );
};

export default memo(VisitEditForm);
