/* eslint-disable @typescript-eslint/no-unused-vars */
import HeaderButtons from "global/atoms/headerButtons/HeaderButtons";
import Spinner from "global/atoms/Spinner/Spinner";
import React, { FunctionComponent, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useHistory, useParams } from "react-router-dom";
import { Loader, Pagination } from "rsuite";
import PublicConnection from "utils/connections/public";
import { IVisitData } from "utils/models";
import VisitsEdit from "views/projects/components/visits/visitsEdit/VisitEdit";
import { getUrlAuthToken } from "../../utils/helpers";

interface INotificationVisitView {}

const NotificationVisitView: FunctionComponent<INotificationVisitView> = () => {
  const { notificationSourceId, visitId } = useParams<{
    notificationSourceId: string;
    visitId: string;
  }>();

  const [projectId, setProjectId] = useState<string>();
  const [organizationId, setOrganizationId] = useState<string>();
  const [visitData, setVisitData] = useState<IVisitData | null>(null);
  const [notificationVisits, setNotificationVisits] = useState<string[]>([]);
  const [paginationBtnSelectors, setPaginationBtnSelectors] = useState<
    string[]
  >([]);
  const [paginationBtnYellowSelectors, setPaginationBtnYellowSelectors] =
    useState<string[]>([]);
  const [paginationBtnGreenSelectors, setPaginationBtnGreenSelectors] =
    useState<string[]>([]);
  const [activePage, setActivePage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();
  const parseResponse = (data: any) => {
    setVisitData(data.data.visit);
    setProjectId(data.data.projectId);
    setOrganizationId(data.data.organizationId);
    setNotificationVisits(data.data.notificationVisits);

    const tmp = data.data.notificationVisitsWithoutReview.map((v: string) => {
      const i = data.data.notificationVisits.findIndex(
        (_v: string) => _v === v
      );
      return '.rs-pagination-btn[title="' + (i + 1) + '"]:after';
    });

    setPaginationBtnSelectors(tmp);

    const tmpGreen = data.data.notificationVisitsWithReview.map((v: string) => {
      const i = data.data.notificationVisits.findIndex(
        (_v: string) => _v === v
      );
      return '.rs-pagination-btn[title="' + (i + 1) + '"]:after';
    });

    setPaginationBtnGreenSelectors(tmpGreen);

    const tmpYellow = data.data.notificationVisitsWithReviewByLoggedUser.map(
      (v: string) => {
        const i = data.data.notificationVisits.findIndex(
          (_v: string) => _v === v
        );
        return '.rs-pagination-btn[title="' + (i + 1) + '"]:after';
      }
    );

    setPaginationBtnYellowSelectors(tmpYellow);

    setActivePage(
      data.data.notificationVisits.findIndex(
        (v: string) => v === data.data.visit.id
      ) + 1
    );
    setIsLoading(false);
  };

  useEffect(() => {
    if (visitId == undefined) return;
    setIsLoading(true);
    PublicConnection.getNotificationVisitData(
      notificationSourceId,
      visitId
    ).then(parseResponse);
  }, [activePage]);

  const visitSwitch = (index: number) => {
    try {
      const _visitId = notificationVisits[index];
      console.log(_visitId);
      if (_visitId) {
        setActivePage(index + 1);
        history.push(
          getUrlAuthToken()
            ? `/public/notification-visit/${notificationSourceId}/${_visitId}?token=${getUrlAuthToken()}`
            : `/public/notification-visit/${notificationSourceId}/${_visitId}`
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <HeaderButtons>
        <div style={{ textAlign: "right" }}>
          <div
            style={{
              marginTop: "5px",
              display: "flex",
              gap: "20px",
              alignItems: "center",
            }}>
            {isLoading && <Loader size={"xs"} />}
            <Pagination
              disabled={isLoading}
              layout={["pager", "skip"]}
              size={"xs"}
              prev
              next
              last
              first
              ellipsis
              boundaryLinks
              total={notificationVisits.length}
              limit={1}
              maxButtons={10}
              activePage={activePage}
              onChangePage={(page) => {
                visitSwitch(page - 1);
              }}
            />
          </div>
        </div>
      </HeaderButtons>
      {!visitData && !organizationId && !projectId ? (
        <Spinner />
      ) : (
        <VisitsEdit
          openAll={true}
          exclusivelyClosed={["visit-distances", "visit-map"]}
          parOrganizationId={organizationId}
          parProjectId={projectId}
        />
      )}

      <Helmet>
        <style>{`
        .rs-pagination-btn {
          border: #dfdfdf 1px solid;
        }
        .rs-pagination-btn-active {
          background: #ffaf38 !important;
          color: #fff !important;
        }

        ${paginationBtnSelectors.join(",")} {
          content: ".";
          background: red;
          position: absolute;
          top: -16px;
          width:100%;
        }  
        ${paginationBtnYellowSelectors.join(",")} {
          content: ".";
          background: yellow;
          position: absolute;
          top: -16px;
          width:100%;
        }
        ${paginationBtnGreenSelectors.join(",")} {
          content: ".";
          background: green;
          position: absolute;
          top: -16px;
          width:100%;
        } 

      `}</style>
      </Helmet>
    </>
  );
};

export default NotificationVisitView;
