import React, { createContext, useMemo } from "react";
import {
  FCC,
  IPhotoReviewModal,
  IQuestionItem,
  IVisitPosition,
  IVisitStatus,
  SetState,
} from "utils/models";
import { IVisitCommentItem } from "./VisitEdit";
import { RawValue } from "./VisitEditForm";
import { IPhotoQuestionValueExtended } from "./VisitElements/Atoms/PhotoQuestion";

export interface IVisitEditContext {
  newStatus: IVisitStatus | null;
  loading: boolean;
  setLoading: (loading: boolean) => void;
  disabledQuestions: Array<string>;
}

export interface IVisitEditPhotoContext {
  setVisitPhotos: (photos: Array<IPhotoQuestionValueExtended>) => void;
  setPhotoReviewModal: (modalData: IPhotoReviewModal | null) => void;
  visitPhotos: IPhotoQuestionValueExtended[];
  photoReviewState: IPhotoReviewModal | null;
}

export interface IVisitEditStaticContext {
  readOnly: boolean;
  isEnableVisitRate?: boolean;
  isEnableVisitComment?: boolean;
  isEnableQuestionComment?: boolean;
  isEnableSaveChangesButton?: boolean;
  visitId: string;
  questionsNestedState: IVisitPosition[];
  questionsFlatState: { [refQuestionId: string]: IQuestionItem };
  itemsFlatState: { [itemId: string]: IVisitPosition };
  locationRefIds: Array<string>;
  generalRefIds: Array<string>;
  comments: Array<IVisitCommentItem>;
  disabledElements: Array<string>;
  hiddenElements: Array<string>;
}

export interface IVisitEditPanelContext {
  panelIds: Array<string>;
  openPanels: Array<string>;
  panelToggle: (panelName: string) => void;
  registerPanelId: (panelId: string) => void;
}

export interface IVisitEditErrorContext {
  setErrors: (errors: { [key: string]: Array<string> }) => void;
  errors: { [key: string]: Array<string> };
}

export const VisitEditContext = createContext<IVisitEditContext | null>(null);
export const VisitEditPhotoContext =
  createContext<IVisitEditPhotoContext | null>(null);
export const VisitEditStaticContext =
  createContext<IVisitEditStaticContext | null>(null);
export const VisitEditPanelContext =
  createContext<IVisitEditPanelContext | null>(null);
export const VisitEditErrorContext =
  createContext<IVisitEditErrorContext | null>(null);

export const VisitEditFormContext = createContext<IVisitFormState>({});
export const VisitEditFormSetterContext = createContext<
  ((fs: IVisitFormState) => void) | null
>(null);

export interface IVisitState
  extends Omit<IVisitEditPanelContext, "registerPanelId" | "panelToggle">,
    Omit<IVisitEditContext, "setLoading">,
    Omit<IVisitEditErrorContext, "setErrors"> {}
export interface IVisitStaticState extends IVisitEditStaticContext {}
export interface IVisitFormState {
  [name: string]: RawValue | Date;
}

export interface IPhotoState
  extends Omit<
    IVisitEditPhotoContext,
    "setVisitPhotos" | "setPhotoReviewModal"
  > {}

interface IVisitEditContextWrapper {
  state: IVisitState;
  setState: SetState<IVisitState>;
  formState: IVisitFormState;
  staticState: IVisitStaticState;
  photoState: IPhotoState;
  setPhotoState: SetState<IPhotoState>;
}

const VisitEditContextWrapper: FCC<IVisitEditContextWrapper> = ({
  state,
  setState,
  formState,
  staticState,
  photoState,
  setPhotoState,
  children,
}) => {
  const visitEditPhotoContext = {
    ...photoState,
    setPhotoReviewModal: (modalData: IPhotoReviewModal | null) => {
      setPhotoState((s) => ({ ...s, photoReviewState: modalData }));
    },
    setVisitPhotos: (_visitPhotos: IPhotoQuestionValueExtended[]) => {
      setPhotoState((s) => ({ ...s, visitPhotos: _visitPhotos }));
    },
  };

  const visitEditStaticContext: IVisitEditStaticContext =
    useMemo<IVisitEditStaticContext>(
      () => ({
        visitId: staticState.visitId,
        readOnly: staticState.readOnly,
        isEnableSaveChangesButton: staticState.isEnableSaveChangesButton,
        isEnableVisitComment: staticState.isEnableVisitComment,
        isEnableQuestionComment: staticState.isEnableQuestionComment,
        isEnableVisitRate: staticState.isEnableVisitRate,
        questionsNestedState: staticState.questionsNestedState,
        questionsFlatState: staticState.questionsFlatState,
        itemsFlatState: staticState.itemsFlatState,
        locationRefIds: staticState.locationRefIds,
        generalRefIds: staticState.generalRefIds,
        comments: staticState.comments,
        disabledElements: staticState.disabledElements,
        hiddenElements: staticState.hiddenElements,
      }),
      [
        staticState.visitId,
        staticState.disabledElements,
        staticState.hiddenElements,
      ]
    );

  const visitEditPanelContext = useMemo<IVisitEditPanelContext>(
    () => ({
      panelIds: state.panelIds,
      openPanels: state.openPanels,
      registerPanelId: (panelId: string) => {
        setState((s) => ({
          ...s,
          panelIds: [...s.panelIds.filter((id) => id !== panelId), panelId],
        }));
      },
      panelToggle(panelName: string) {
        setState((s) => ({
          ...s,
          openPanels: s.openPanels.includes(panelName)
            ? s.openPanels.filter((p) => p !== panelName)
            : [...s.openPanels.filter((p) => p !== panelName), panelName],
        }));
      },
    }),
    [state.panelIds, state.openPanels]
  );

  const visitEditErrorContext = useMemo<IVisitEditErrorContext>(
    () => ({
      errors: state.errors,
      setErrors: (errors: { [key: string]: Array<string> }) => {
        setState((s) => ({ ...s, errors: errors }));
      },
    }),
    [state.errors]
  );

  const visitEditContext = useMemo<IVisitEditContext>(
    () => ({
      newStatus: state.newStatus,
      loading: state.loading,
      disabledQuestions: state.disabledQuestions,
      setLoading: (l: boolean) => {
        setState((s) => ({ ...s, loading: l }));
      },
    }),
    [state.newStatus, state.loading, state.disabledQuestions]
  );

  return (
    <VisitEditStaticContext.Provider value={visitEditStaticContext}>
      <VisitEditPanelContext.Provider value={visitEditPanelContext}>
        <VisitEditErrorContext.Provider value={visitEditErrorContext}>
          <VisitEditPhotoContext.Provider value={visitEditPhotoContext}>
            <VisitEditContext.Provider value={visitEditContext}>
              <VisitEditFormContext.Provider value={formState}>
                {children}
              </VisitEditFormContext.Provider>
            </VisitEditContext.Provider>
          </VisitEditPhotoContext.Provider>
        </VisitEditErrorContext.Provider>
      </VisitEditPanelContext.Provider>
    </VisitEditStaticContext.Provider>
  );
};

export default VisitEditContextWrapper;
