import React, { FunctionComponent, useEffect, useState } from "react";
import MapWrapper from "./MapWrapper";
import InnerMap from "./InnerMap";
import InfoWindow from "./InfoWindow";
import ClusterMarkers from "./ClusterMarkers";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import Marker from "./Marker";
import PolyLine from "./PolyLine";

export interface IPoint {
  id: string;
  lat: number;
  lon: number;
  selected: boolean;
  hidden: boolean;
  icon?: string;
  title?: string;
  label?: string;
  markerObj?: google.maps.Marker; // todo: remove
}

interface IMap {
  points?: Array<IPoint>;
  polylines?: Array<google.maps.PolylineOptions>;
  center?: google.maps.LatLngLiteral;
  zoom?: number;
  infoWindow?: google.maps.InfoWindowOptions;
  onPointMouseOver?: (p: IPoint) => void;
  onPointMouseOut?: (p: IPoint) => void;
  onPointClick?: (p: IPoint) => void;
  clusterMarkersCreated?: (clusters: MarkerClusterer) => void;
  onClusterMarkerClick?: (
    pos: google.maps.LatLngLiteral,
    points: Array<IPoint>
  ) => void;
  onMapClick?: (pos: google.maps.LatLngLiteral, map: google.maps.Map) => void;
  allowPointsCluster?: boolean;
}

const Map: FunctionComponent<IMap> = (props) => {
  const [map, setMap] = useState<google.maps.Map>();
  const [infoWindowOpen, setInfoWindowOpen] = useState<boolean>(false);
  const defaultCenter = {
    lat: 51.919438,
    lng: 19.145136,
  };

  useEffect(() => {
    if (props.onMapClick && map) {
      map.addListener("click", (event) => {
        props.onMapClick?.(event, map);
      });
    }
  }, [map]);

  useEffect(() => {
    if (props.infoWindow?.content) {
      setInfoWindowOpen(true);
    } else setInfoWindowOpen(false);
  }, [props.infoWindow?.content]);

  return (
    <MapWrapper>
      <InnerMap
        center={props.center ?? defaultCenter}
        zoom={props.zoom ?? 7}
        mapCallback={(map: google.maps.Map) => setMap(map)}>
        {map && (
          <>
            {(props.allowPointsCluster === undefined ||
              props.allowPointsCluster) &&
              (props.points?.length ?? 0) > 0 && (
                <ClusterMarkers
                  map={map}
                  points={props.points ?? []}
                  clustersCreatedCallback={props.clusterMarkersCreated}
                  onPointClick={props.onPointClick}
                  onClusterClick={props.onClusterMarkerClick}
                />
              )}

            {props.allowPointsCluster === false &&
              (props.points?.length ?? 0) > 0 &&
              props.points?.map((p) => (
                <Marker
                  key={`mk-${p.id}`}
                  point={p}
                  map={map}
                  onPointClick={props.onPointClick}
                  onPointMouseOut={props.onPointMouseOut}
                  onPointMouseOver={props.onPointMouseOut}
                />
              ))}

            {props.polylines &&
              props.polylines.length > 0 &&
              props.polylines.map((p, i) => (
                <PolyLine key={`pl-${i}`} polyLine={p} map={map} />
              ))}

            {props.infoWindow && (
              <InfoWindow
                options={props.infoWindow}
                open={infoWindowOpen}
                map={map}
              />
            )}
          </>
        )}
      </InnerMap>
    </MapWrapper>
  );
};
export default Map;
