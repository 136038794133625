import React, { FunctionComponent } from "react";
import styles from "../styles.module.scss";
import { IItemBody } from "../DoubleSideThinPicker";
import { IItem } from "../DoubleSideThinPicker";

const BaseThinItemBody: FunctionComponent<IItemBody> = (props) => {
  const getCheckboxClass = (item: IItem) => {
    return `${styles.checkboxItem} ${
      props.checked.includes(item.id) ? styles.checked : ""
    }`;
  };

  return (
    <>
      <div className={styles.itemRow} key={props.item.id}>
        <div className={styles.wrapper}>
          <span
            data-id={props.item.id}
            onClick={props.handleToggleCheckbox.bind(null, props.item.id)}
            className={getCheckboxClass(props.item)}
          />
          <span className={styles.itemName}>{props.item.name}</span>
        </div>
      </div>
    </>
  );
};
export default BaseThinItemBody;
