import React, { FunctionComponent } from "react";
import VisitsEdit from "views/projects/components/visits/visitsEdit/VisitEdit";

interface IVisitView {}

const VisitView: FunctionComponent<IVisitView> = () => {
  return <VisitsEdit />;
};

export default VisitView;
