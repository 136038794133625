import { AxiosPromise } from "axios";
import { getBaseApiUrl } from "../../../../utils/helpers";
import AxiosHelper from "../../../../utils/AxiosHelper";

// const baseUrl = `${getBaseApiUrl()}/organizations/${getOrganizationId()}/visits-organization-report`;
const baseUrl = `${getBaseApiUrl()}/organizations/visits-organization-report`;
const VisitReportsConnection = {
  scheduleList: (requestFilter): AxiosPromise<any> => {
    return AxiosHelper.post(`${baseUrl}/scheduler/list`, requestFilter);
  },
  schedulerForm: (): AxiosPromise<any> => {
    return AxiosHelper.get(
      `${baseUrl}/scheduler/form`,
      undefined,
      "VisitReportsConnectionScheduleForm"
    );
  },
  schedulerDelete: (id): AxiosPromise<any> => {
    return AxiosHelper.delete(`${baseUrl}/scheduler/${id}`);
  },
  getFiltersScheduler: (): AxiosPromise<any> => {
    return AxiosHelper.get(
      `${baseUrl}/scheduler/form`,
      undefined,
      "VisitReportsConnectionScheduleForm"
    );
  },
  requestCreateSchedule: (data): AxiosPromise<any> => {
    return AxiosHelper.post(`${baseUrl}/scheduler`, data);
  },
};

export default VisitReportsConnection;
