import {
  IVisitReportCreateRequest,
  IVisitReportForm,
  IVisitReportList,
  IVisitReportListRequest,
} from "../models";
import AxiosHelper from "../AxiosHelper";
import { AxiosPromise } from "axios";
import { getBaseApiUrl, getOrganizationId } from "../helpers";

const baseUrl = `${getBaseApiUrl()}/organizations/visits-organization-report`;
const baseOrganizationUrl = `${getBaseApiUrl()}/organizations/${getOrganizationId()}/notifications`;
const VisitReportsConnection = {
  create: (requestData: IVisitReportCreateRequest): AxiosPromise<any> => {
    return AxiosHelper.post(
      baseUrl,
      requestData,
      undefined,
      "VisitReportsConnectionCreate"
    );
  },
  list: (
    requestData: IVisitReportListRequest
  ): AxiosPromise<IVisitReportList> => {
    return AxiosHelper.post(
      `${baseUrl}/list`,
      requestData,
      undefined,
      "VisitReportsConnectionList"
    );
  },
  form: (): AxiosPromise<IVisitReportForm> => {
    return AxiosHelper.get(
      `${baseUrl}/form`,
      undefined,
      "VisitReportsConnectionForm"
    );
  },
  schedulerForm: (): AxiosPromise<any> => {
    return AxiosHelper.get(
      `${baseUrl}/scheduler/form`,
      undefined,
      "VisitReportsConnectionScheduleForm"
    );
  },
  getSchedulerEditForm: (id): AxiosPromise<any> => {
    return AxiosHelper.get(
      `${baseUrl}/scheduler/form/${id}`,
      undefined,
      "VisitReportsConnectionScheduleEditForm"
    );
  },
  requestCreateSchedule: (data): AxiosPromise<any> => {
    return AxiosHelper.post(`${baseUrl}/scheduler`, data);
  },
  requestUpdateSchedule: (id, data): AxiosPromise<any> => {
    return AxiosHelper.put(`${baseUrl}/scheduler/${id}`, data);
  },
  download: (reportId: string): AxiosPromise<any> => {
    return AxiosHelper.getBlob(`${baseUrl}/${reportId}/download`);
  },

  getTemplate: (templateId: string): AxiosPromise<any> => {
    return AxiosHelper.get(`${baseUrl}/templates/${templateId}/form`);
  },
  getAddTemplate: (): AxiosPromise<any> => {
    return AxiosHelper.get(`${baseUrl}/templates/form`);
  },
  updateTemplate: (templateId: string, data): AxiosPromise<any> => {
    return AxiosHelper.put(`${baseUrl}/templates/${templateId}`, data);
  },
  removeTemplate: (templateId: string): AxiosPromise<any> => {
    return AxiosHelper.delete(`${baseUrl}/templates/${templateId}`);
  },
  saveTemplate: (data): AxiosPromise<any> => {
    return AxiosHelper.post(`${baseUrl}/templates`, data);
  },
  getUsersFilterForm: (): Promise<any> => {
    return AxiosHelper.post(`${baseOrganizationUrl}/form/filter-users`, {
      isEnableLocation: false,
    });
  },
};

export default VisitReportsConnection;
